import { AXIOS_API_SERVER } from '@common/constants';
import { useFormData } from '@components/form-api';
import { envConfigs } from '@config/envConfigs';
import {
  Button,
  Checkbox,
  ComponentType,
  Icon,
  ModalLoader,
  ToastMessage,
  getAxiosApiInstanceByName,
  toastMessage,
} from '@gcl/react-core';
import { useState } from 'react';
import { BsPinMapFill } from 'react-icons/bs';
import './LogTable.css';

const getFilenameFromContentDisposition = (contentDisposition: string): string | null => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition || '');
  if (matches && matches[1]) {
    return matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
  }
  return null;
};

const downloadFile = async (blob: Blob, fileName: string): Promise<void> => {
  if (!blob) return;
  const arrayBuffer = await blob.arrayBuffer();
  const downloadElement = document.createElement('a');
  downloadElement.style.display = 'none';
  downloadElement.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  window.URL.revokeObjectURL(downloadElement.href);
};

export const LogTable = ({ handleOpenPopup }: { handleOpenPopup: (boreholeId: string) => void }) => {
  const { response } = useFormData();
  const [isLoading, setIsLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState<{
    [key: string]: boolean;
  }>({});
  const selectedRowsSize = Object.entries(checkedRows).length;
  const hasCheckedAllRows =
    Object.keys(checkedRows).length > 0 &&
    JSON.stringify(Object.keys(checkedRows).sort()) == // eslint-disable-line
      JSON.stringify(response?.boreholes?.map((value) => value.id).sort());
  const onClickDownloadZip = async () => {
    const keys = Object.keys(checkedRows);
    const requests =
      response?.boreholes
        ?.filter((value) => keys.includes(value.id))
        ?.map((value) => {
          return {
            attachmentUrl: value.fileName,
            jobNum: value.bljobnum,
            logNum: value.blbhnum,
            hasAgsFile: value.hasAgsFile,
          };
        }) ?? [];
    setIsLoading(true);
    await getAxiosApiInstanceByName(AXIOS_API_SERVER)
      .post<any>(`${envConfigs.apiBaseUrl}/BoreholeLog/DownloadFiles`, requests, {
        responseType: 'blob',
      })
      .then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        downloadFile(
          new Blob([res.data], { type: 'application/octet-stream' }),
          getFilenameFromContentDisposition(contentDisposition) || `${new Date().toISOString()}.zip`,
        );
      })
      .catch((error) => {
        toastMessage(
          <ToastMessage
            message="Failed to download the zip file"
            toastOptions={{ type: 'error', closeOnClick: true, autoClose: 3000 }}
          />,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickDownloadAgs = async (jobNum: string, logNum?: string) => {
    setIsLoading(true);
    await getAxiosApiInstanceByName(AXIOS_API_SERVER)
      .post<any>(`${envConfigs.apiBaseUrl}/BoreholeLog/DownloadAgs`, { logNum, jobNum })
      .then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        const filename = getFilenameFromContentDisposition(contentDisposition) || `${new Date().toISOString()}.ags`;
        const blob = new Blob([res.data], { type: 'application/octet-stream' });
        downloadFile(blob, filename);
      })
      .catch((error) => {
        toastMessage(
          <ToastMessage
            message="Failed to download the ags file"
            toastOptions={{ type: 'error', closeOnClick: true, autoClose: 3000 }}
          />,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeCheckbox = (id: string, checked: boolean) => {
    setCheckedRows((oldCheckedRows) => {
      const newCheckedRows = { ...oldCheckedRows };
      if (checked) return { ...newCheckedRows, [id]: checked };
      delete newCheckedRows[id];
      return newCheckedRows;
    });
  };

  const onChangeCheckAll = (checked: boolean) => {
    if (!checked) setCheckedRows({});
    else {
      const newCheckedRows = response?.boreholes?.reduce((prev, curr) => {
        prev[curr.id] = true;
        return prev;
      }, {} as any);
      setCheckedRows(newCheckedRows);
    }
  };

  return (
    <>
      <ModalLoader active={isLoading} />
      <div className="responsive-borehole-table">
        <table className="boreholes table">
          <thead>
            <tr>
              <th scope="col">
                {(response?.boreholes?.length ?? 0) > 0 && (
                  <>
                    <Button className="gcl-button-zip-all" small type={ComponentType.Info} onClick={onClickDownloadZip}>
                      Zip {selectedRowsSize > 0 ? `(${selectedRowsSize})` : ''}
                    </Button>
                    &nbsp;
                    <Checkbox id="selectAll" checked={hasCheckedAllRows} onChange={onChangeCheckAll} />
                  </>
                )}
              </th>
              <th scope="col">&nbsp;</th>
              <th scope="col">
                AGS
                {/* &nbsp;
                  <Icon
                    className="gcl-ic-btn-md"
                    onClick={() => onClickDownloadAgs(response?.boreholes?.[0]?.bljobnum as string)}
                    iconName="ArrowDownCircle"
                  /> */}
              </th>
              <th scope="col">Attachment</th>
              <th scope="col">Job Code</th>
              <th scope="col">Work Order</th>
              <th scope="col">Borehole Log</th>
              <th scope="col">E coordinate</th>
              <th scope="col">N coordinate</th>
              <th scope="col">Remarks</th>
              <th scope="col">H Pile</th>
              <th scope="col">Grade III</th>
              <th scope="col">Grade II</th>
              <th scope="col">Ground Level</th>
            </tr>
          </thead>
          <tbody>
            {response?.boreholes !== undefined &&
              response?.boreholes.map((borehole, index) => (
                <tr key={borehole.id}>
                  <td>
                    {(!!borehole.fileName || !!borehole.hasAgsFile) && (
                      <Checkbox
                        id={borehole.id}
                        checked={checkedRows[borehole.id]}
                        onChange={(checked) => onChangeCheckbox(borehole.id, checked)}
                      />
                    )}
                  </td>
                  <td>
                    <BsPinMapFill className="gcl-clickable" onClick={() => handleOpenPopup(borehole.id)} />
                  </td>
                  <td>
                    {borehole.hasAgsFile ? (
                      <Icon
                        className="gcl-ic-btn-md"
                        onClick={() => onClickDownloadAgs(borehole.bljobnum, borehole.blbhnum)}
                        iconName="ArrowDownCircle"
                      />
                    ) : null}
                  </td>
                  <td>
                    {borehole.fileName ? (
                      <Icon
                        className="gcl-ic-btn-md"
                        onClick={() => window.open(borehole.fileName as string, '_blank')}
                        iconName="ArrowDownCircle"
                      />
                    ) : null}
                  </td>
                  <td>{borehole.bljobnum}</td>
                  <td>{borehole.blwrkord}</td>
                  <td>{borehole.blbhnum}</td>
                  <td>{borehole.bleast}</td>
                  <td>{borehole.blnorth}</td>
                  <td>{borehole.blrmks}</td>
                  <td>{borehole.blngT200}</td>
                  <td>{borehole.blG3ROCK}</td>
                  <td>{borehole.blG2ROCK}</td>
                  <td>{borehole.blgndlvl}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
