import { useAppConfigs } from '@config/config.hooks';
import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { ReportErrorButton } from './ReportErrorButton';
import './appErrorBoundary.scss';

export interface IAppErrorBoundaryProps extends FallbackProps {}

export const AppErrorBoundary: React.FC<IAppErrorBoundaryProps> = ({ error }) => {
  const appConfigs = useAppConfigs();
  return (
    <div className="gcl-borehole-log-app-error-boundary-container">
      <div className="gcl-borehole-log-app-error-boundary-content">
        <div className="gcl-borehole-log-app-error-boundary-logo">
          <img alt="gammon-logo" src="/images/gammon-logo.png" className="gcl-mr-sm" />
        </div>
        <div className="gcl-borehole-log-app-error-boundary-text">
          <b className="gcl-fs-md">System is currently unavailable due to unknown error.</b>
          <br />
          <br />
          <ReportErrorButton errorMessage={error.message} traceId={appConfigs.configs.traceId} />
        </div>
      </div>
    </div>
  );
};
