import { appendClassName, ComponentType, IReactBaseProps, getColorByComponentType, Icon, Grid } from '@gcl/react-core';
import * as React from 'react';
import './note.scss';

export interface INoteProps<C extends React.ReactElement<typeof Icon>> extends IReactBaseProps {
  className?: string;
  componentType?: ComponentType;
  prefixIcon?: C;
}

export const Note = <C extends React.ReactElement>({
  children,
  className,
  componentType = ComponentType.Secondary,
  prefixIcon,
}: INoteProps<C>) => {
  const PrefixIcon =
    prefixIcon && React.isValidElement<typeof Icon>(prefixIcon)
      ? React.cloneElement(prefixIcon, {
          className: 'gcl-mr-sm',
          style: { color: getColorByComponentType(componentType) },
        })
      : null;
  return (
    <Grid
      container
      flex-direction="row"
      flexWrap="nowrap"
      width="fit-content"
      className={appendClassName('gcl-note-container gcl-p-sm', className)}
      style={{ borderColor: getColorByComponentType(componentType) }}
    >
      {prefixIcon && <Grid item>{PrefixIcon}</Grid>}
      <Grid item>{children}</Grid>
    </Grid>
  );
};
