import { IErrorResponse } from '@common/api.models';
import { AxiosError } from 'axios';
import { IHandledError } from './error.models';
import { lowerFirstLetter } from './Primitives/text.services';

export const apiErrorHandle: (error: AxiosError<IErrorResponse>, genericErrorMessage: string) => IHandledError = (
  error,
) => {
  const errorCode = error.response?.data?.code;
  if (!error.response?.status) {
    // system error
    return {
      errorType: 'DirectDisplayError',
      errorMessage: undefined,
      formErrors: undefined,
      errorCode,
    };
  }
  if (error.response?.status === 500) {
    return { errorType: 'DirectDisplayError', errorMessage: undefined, formErrors: undefined, errorCode };
  }
  if (error.response.status === 422) {
    const errorMessage = error.response.data.messages?.join('\n') || '';
    return {
      errorType: 'DirectDisplayError',
      errorMessage: errorMessage.length <= 0 ? undefined : errorMessage,
      formErrors: undefined,
      errorCode,
    };
  }
  if (error.response.status === 403) {
    // TODO: not enough permission handle
    return {
      errorType: 'DirectDisplayError',
      errorMessage: 'You do not have enough permissions to perform this action',
      formErrors: undefined,
      errorCode,
    };
  }
  if (error.response.status === 401) {
    // TODO: unauthorized error handle
    return {
      errorType: 'DirectDisplayError',
      errorMessage: 'You do not have enough permission to perform this action',
      formErrors: undefined,
      errorCode,
    };
  }
  if (error.response.status === 400) {
    const errors = error?.response?.data?.errors ?? {};
    const returnError: { [key: string]: string } = {};
    Object.keys(errors).forEach((errorKey) => {
      const split = errorKey.split('.');
      const key = split.map((splitItem) => lowerFirstLetter(splitItem)).join('.');
      const item = errors[errorKey];
      if (!item) {
        return;
      }
      if (typeof item === 'string') {
        returnError[key] = item;
      } else returnError[key] = (item as string[]).join('\n');
    });

    return {
      errorType: 'FormError',
      errorMessage: undefined,
      formErrors: returnError,
      errorCode,
    };
  }
  // Directly display error for debugging purpose
  if (process.env.NODE_ENV === 'development') {
    return {
      errorType: 'DirectDisplayError',
      errorMessage: error.message,
      formErrors: undefined,
      errorCode,
    };
  }

  return {
    errorType: 'DirectDisplayError',
    errorMessage: 'Unexpected error occurred, please contact support to help.',
    formErrors: undefined,
    errorCode,
  };
};
