import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from '@gcl/react-core';
import { routes } from './routes.models';

export interface IProtectedRouteProps {
  element: React.ReactNode;
  isRequiredLogin?: boolean;
}

export const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = ({ element, isRequiredLogin = true }) => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const url = new URL(window.location.href.replace(/#/g, '?'));
  const hashParamCode = url.searchParams.get('code');
  const hashParamSessionState = url.searchParams.get('session_state');
  const isLoggingIn = !!(hashParamCode && hashParamSessionState);

  const hasAccessRight = true;

  React.useEffect(() => {
    if (isLoggingIn) return;
    if (!isLoggedIn && isRequiredLogin) {
      navigate(routes.common.requireLogin.path);
    }
  }, [isLoggedIn, isLoggingIn]);

  return hasAccessRight ? <>{element}</> : <Navigate to={routes.common.noAccessRight.path} />;
};
