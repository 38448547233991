import { useAppConfigs } from '@config/config.hooks';
import { Grid, Heading, HeadingType, Scrollbar, getMsalAccount, useMsal } from '@gcl/react-core';
import { routes } from '@routes/routes.models';
import * as React from 'react';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import './AgsAdminLayout.scss';

export const AgsContext = React.createContext<any>({});

export interface IAgsAdminLayoutProps {}

export const AgsAdminLayout: React.FC<IAgsAdminLayoutProps> = ({}) => {
  const { instance } = useMsal();
  const account = getMsalAccount(instance);
  const appConfigs = useAppConfigs();
  const [lastProject, setLastProject] = React.useState<string | undefined>(undefined);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AgsContext.Provider value={{ lastProject, onChangeLastProject: (project: string) => setLastProject(project) }}>
      <Heading as={HeadingType.h2} styledAs={HeadingType.h6}>
        AGS import and audit data
      </Heading>
      <Grid container>
        <Grid item flexBasis="100" className="gcl-borehole-log-admin-menu-container">
          <ul>
            <li>
              <Link to={routes.common.ags.upload.path}>Upload Borehole Log</Link>
            </li>
            {appConfigs.configs.approvers?.includes(account?.username || '') && (
              <li>
                <Link
                  to={{
                    pathname: routes.common.ags.approve.path,
                    search: lastProject ? `?jobNo=${lastProject}` : undefined,
                  }}
                >
                  Review & Approve
                </Link>
              </li>
            )}
          </ul>
        </Grid>
        <Grid item flex="1 1 auto" className="gcl-borehole-log-admin-layout-outlet">
          <Scrollbar>
            <Outlet />
          </Scrollbar>
        </Grid>
      </Grid>
    </AgsContext.Provider>
  );
};
