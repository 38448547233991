import { reactAppPrefix } from './config.models';

export interface IEnvConfigs {
  authority?: string;
  apiScope?: string;
  apiBaseUrl?: string;
  env?: string;
  redirectUrl?: string;
  clientId?: string;
  appInsightsKey?: string;
  validIssuers?: string;
  enableLir?: boolean;
  approvers?: string[];
}

export const envConfigs: IEnvConfigs = {
  authority: process.env[`${reactAppPrefix}AUTHORITY`],
  apiScope: process.env[`${reactAppPrefix}API_SCOPE`],
  apiBaseUrl: process.env[`${reactAppPrefix}API_BASE_URL`],
  env: process.env[`${reactAppPrefix}ENV`],
  redirectUrl: process.env[`${reactAppPrefix}REDIRECT_URL`],
  clientId: process.env[`${reactAppPrefix}CLIENT_ID`],
  validIssuers: process.env[`${reactAppPrefix}VALID_ISSUERS`],
  approvers: process.env[`${reactAppPrefix}APPROVERS`]?.split(',')?.map((s) => s.trim().toLowerCase()) || [],
};
