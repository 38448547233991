import { Icon } from '@gcl/react-core';
import { Borehole } from '../../../models/borehole';
import './PinPopup.scss';

interface PinPopupProps {
  borehole: Borehole;
}

export const PinPopup = (props: PinPopupProps) => {
  const { borehole } = props;

  return (
    <table className="borehole-map-table pin">
      <tbody>
        <tr>
          <td>Job Code</td>
          <td>{borehole.bljobnum}</td>
        </tr>
        <tr>
          <td>Work Order</td>
          <td>{borehole.blwrkord}</td>
        </tr>
        <tr>
          <td>Borehole Log</td>
          <td>{borehole.blbhnum}</td>
        </tr>
        <tr>
          <td>E coordinate</td>
          <td>{borehole.bleast}</td>
        </tr>
        <tr>
          <td>N coordinate</td>
          <td>{borehole.blnorth}</td>
        </tr>
        <tr>
          <td>Remarks</td>
          <td>{borehole.blrmks}</td>
        </tr>
        <tr>
          <td>H Pile</td>
          <td>{borehole.blngT200}</td>
        </tr>
        <tr>
          <td>Grade III</td>
          <td>{borehole.blG3ROCK}</td>
        </tr>
        <tr>
          <td>Grade II</td>
          <td>{borehole.blG2ROCK}</td>
        </tr>
        <tr>
          <td>Ground Level</td>
          <td>{borehole.blgndlvl}</td>
        </tr>
        <tr>
          <td>Attachment</td>
          <td>
            {borehole.fileName ? (
              <Icon
                className="gcl-ic-btn-md"
                onClick={() => window.open(borehole.fileName as string, '_blank')}
                iconName="ArrowDownCircle"
              />
            ) : null}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
