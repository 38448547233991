import { LatLng } from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Polygon } from '../../../models/polygon';

import { useSearchBoreholes } from '@components/borehole.services';
import { useFormAPI, useFormData } from '@components/form-api';
import { ModalLoader } from '@gcl/react-core';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as React from 'react';

export const MapEditor = () => {
  const { onPolygonChange, onPolygonSearch, onPolygonDelete, updateFormData } = useFormAPI();
  const { polygon } = useFormData();

  const { data, isLoading } = useSearchBoreholes({ polygon });

  React.useEffect(() => {
    if (!data) return;
    updateFormData(data.data);
  }, [JSON.stringify(data)]);

  const onEdited = () => {
    console.log('onEdited');
  };

  const onCreated = (polyline: any) => {
    console.log('onCreated', 'points', polyline.layer.editing.latlngs[0]);

    const createPolygon: Polygon = { points: [] };
    polyline.layer.editing.latlngs[0][0].map((latlng: LatLng) => {
      console.debug({ ...latlng });
      createPolygon.points.push({ ...latlng });
    });

    console.log('onCreated', createPolygon);

    onPolygonChange(createPolygon);
  };

  const onDeleted = () => {
    console.log('onDeleted');
    onPolygonDelete();
  };

  return (
    <FeatureGroup>
      <ModalLoader active={isLoading} />
      <EditControl
        position="topleft"
        draw={{
          polyline: false,
          polygon: {
            distance: 25,
            vertices: false,
          },
          marker: false,
          rectangle: false,
          circle: false,
          circlemarker: false,
        }}
        onEdited={onEdited}
        onCreated={onCreated}
        onDeleted={onDeleted}
      />
    </FeatureGroup>
  );
};
