/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { Outlet } from 'react-router-dom';

import usePrompt from '@common/prompt.hooks';
import { useAppConfigs } from '@config/config.hooks';
import { ToastProvider, UnsavedChangesPrompt, useIsLoggedIn } from '@gcl/react-core';
import './Layout.scss';
import { TopNavBar } from './TopNavBar';

export interface ILayoutProps {
  errors?: any;
}

export const Layout: React.FunctionComponent<ILayoutProps> = () => {
  const appConfigs = useAppConfigs();
  const isLoggedInHookValue = useIsLoggedIn();

  if (!isLoggedInHookValue) return <Outlet />;

  const content = (
    <>
      <TopNavBar />
      <div className="gcl-borehole-log-layout-outlet">
        <Outlet />
      </div>
    </>
  );

  return (
    <div className={`gcl-borehole-log-layout-container `}>
      <UnsavedChangesPrompt
        shouldBlockUIPredicate={() => {
          return appConfigs.configs.isAnyActionLoading ?? false;
        }}
        message="There are actions still loading, if you navigate to another page, this might cause data lost, do you still want to continue?"
        usePrompt={usePrompt}
      />
      <ToastProvider />
      <div className="gcl-borehole-log-layout-global-page-container">{content}</div>
    </div>
  );
};
