import { useSearchBoreholes } from '@components/borehole.services';
import { ModalLoader } from '@gcl/react-core';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useFormAPI, useFormData } from '../../form-api';
import './FreeText.css';

interface ISearchFreeText {}

export const SearchFreeText: React.FC<ISearchFreeText> = () => {
  const { onKeywordChange, onKeywordSearch, updateFormData } = useFormAPI();
  const { keyword } = useFormData();
  const [keywordFilter, setKeywordFilter] = React.useState<string>();
  // Introduce a state to force re-render
  const [key, setKey] = React.useState<number>(0);
  const { data, isLoading } = useSearchBoreholes({ keyword: keywordFilter }, key);

  React.useEffect(() => {
    if (!data) return;
    updateFormData(data.data);
  }, [JSON.stringify(data)]);

  const keyboardChanged = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('keyboardChanged', e.target?.value);
    onKeywordChange(e.target?.value);
  };

  const onSearchClicked = () => {
    setKeywordFilter(keyword);
    setKey((prevKey) => prevKey + 1);
  };

  const onClearClicked = () => {
    console.log('keyboardChanged', 'onClearClicked', keyboardChanged);
    onKeywordChange('');
  };

  return (
    <form className="">
      <ModalLoader active={isLoading} />
      <label className="form-label" htmlFor="formKeyword">
        Job No/BoreHole No:
      </label>
      <input
        id="formKeyword"
        type="text"
        placeholder="Input and press enter"
        value={keyword ?? ''}
        onChange={keyboardChanged}
        className="form-control gcl-mb-xs"
      />
      <input type="button" value="Search" className="btn btn-primary gcl-mr-xs" onClick={onSearchClicked} />
      <input type="reset" value="Clear" className="btn btn-secondary" onClick={onClearClicked} />
    </form>
  );
};
