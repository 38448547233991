export const routes = {
  home: {
    path: '/',
  },
  common: {
    noAccessRight: {
      path: '/no-access-right',
    },
    requireLogin: {
      path: '/require-login',
    },
    pageNotFound: {
      path: '/page-not-found',
    },
    accountLocked: {
      path: '/account-locked',
    },
    ags: {
      path: '/ags',
      upload: {
        path: '/ags/upload',
      },
      approve: {
        path: '/ags/approve',
      },
    },
    admin: {
      path: '/admin',
    },
  },
};
