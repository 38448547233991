import * as React from 'react';

export interface INoAccessRightProps {}

export const NoAccessRight: React.FC<INoAccessRightProps> = ({}) => {
  return (
    <div className="gcl-p-sm">
      You don&apos;t have permission to view this page, please contact admin for assistance.
    </div>
  );
};
