import { FormDataProvider } from '@components/form-api';
import { AzureAdAuth, ModalLoader } from '@gcl/react-core';
import '@gcl/react-core/dist/main.css';
import '@gcl/react-core/dist/themes.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';
import { AppErrorBoundary } from './common/error/AppErrorBoundary';
import { AppConfig } from './config/AppConfig';
import { getEnvConfigs } from './config/config.services';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Routes } from './routes/Routes';

interface IIndexProps {}

const Index: React.FC<IIndexProps> = ({}) => {
  const envConfigs = getEnvConfigs();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
      },
    },
  });

  // const apiOrigin = new URL(envConfigs.apiBaseUrl || '')?.origin;

  return (
    <ErrorBoundary FallbackComponent={AppErrorBoundary}>
      <AppConfig>
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback={<ModalLoader />}>
            <AzureAdAuth
              configs={{
                clientId: envConfigs.clientId as string,
                authority: envConfigs.authority as string,
                redirectUrl: envConfigs.redirectUrl as string,
              }}
            >
              <Helmet>
                <meta charSet="utf-8" />
                <title>Borehole Log</title>
              </Helmet>
              <FormDataProvider>
                <Routes />
              </FormDataProvider>
            </AzureAdAuth>
          </React.Suspense>
        </QueryClientProvider>
      </AppConfig>
    </ErrorBoundary>
  );
};
const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
