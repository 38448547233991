import { useAppConfigs } from '@config/config.hooks';
import { showToastWithReport } from '@core/ApiErrorDisplay';
import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';

export interface ILogginErrorBoundaryProps extends FallbackProps {}
export const LogginErrorBoundary: React.FC<ILogginErrorBoundaryProps> = ({ error, resetErrorBoundary }) => {
  const appConfigs = useAppConfigs();
  React.useEffect(() => {
    showToastWithReport({ genericError: error?.message, error }, appConfigs.configs.traceId);

    return () => {
      resetErrorBoundary();
    };
  }, [error?.message]);

  return <></>;
};
