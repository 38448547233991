export const transformCorrespondenceDirection = (inOut: number): string => {
  let text = '';
  const inOutNum = typeof inOut === 'number' ? inOut : Number(inOut);
  switch (inOutNum) {
    case 1:
      text = 'Incoming';
      break;

    case 2:
      text = 'Outgoing';
      break;
    default:
      text = 'All';
      break;
  }
  return text;
};

export const prefixNumberWithZeros = (number: number, digits = 5): string => {
  // eslint-disable-next-line
  let precedingZeros = '';
  for (let index = 0; index < digits; index += 1) {
    precedingZeros += '0';
  }
  return `${precedingZeros}${number}`.slice(-1 * digits);
};

export const getSiteRelativeUrlByFullSiteUrl = (siteFullUrl: string): string => {
  return `/${siteFullUrl.split('/').slice(3).join('/')}`;
};

export const getSiteBaseUrlByFullSiteUrl = (siteFullUrl: string): string => {
  const secondLastIndex = siteFullUrl.lastIndexOf('/', siteFullUrl.lastIndexOf('/') - 1);
  return siteFullUrl.substr(0, secondLastIndex);
};

export const lowerFirstLetter = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const splitStringByLastOccurenceOf = (
  str = '',
  deliminator = '-',
): { firstHalf: string; secondHalf: string } => {
  const pos = str.lastIndexOf(deliminator);
  const secondHalf = str.substring(pos + 1);
  const firstHalf = str.substring(0, pos);
  return {
    firstHalf,
    secondHalf,
  };
};

export const textIsNullOrEmpty = (text: string) => {
  return text === undefined || text === null || text === '';
};

interface isAllEmptyOrAllValidParams {
  value: string | number;
  required: boolean;
}

export const isAllEmptyOrAllValid = (list: isAllEmptyOrAllValidParams[]) => {
  const optionalItems = list.filter(({ required }) => {
    return !!required;
  });
  let emptyCount = 0;
  for (let i = 0; i < optionalItems.length; i += 1) {
    if (optionalItems[i].value === null || optionalItems[i].value === undefined || optionalItems[i].value)
      emptyCount += 1;
  }
  if (emptyCount === optionalItems.length) {
    return true;
  }
  if (emptyCount === 0) {
    return true;
  }
  return false;
};

export const isAllStringsEmpty = (...strings: string[]) => {
  let emptyCount = 0;
  for (let i = 0; i < strings.length; i += 1) {
    if (strings[i] === null || strings[i] === undefined || strings[i] === '') emptyCount += 1;
  }
  if (emptyCount === strings.length) {
    return true;
  }
  return false;
};

export const getFilenameAndExt = (str: string) => {
  const lastDotIndex = str.lastIndexOf('.');
  return { fileName: str.substring(0, lastDotIndex), extension: str.substring(lastDotIndex + 1) };
};

export const isMatchPattern = (str: string, isMatchStr: string) => {
  return str === isMatchStr;
};

export const keySeparator = (originalKey: string, separator: string) => {
  const split = originalKey.split(separator);
  return { id: split[0], key: split[1] };
};
