import { useAppConfigs } from '@config/config.hooks';
import { ApiErrorDisplay } from '@core/ApiErrorDisplay';
import { ApiSuccessToast } from '@core/ApiSuccessToast';
import { Button, ComponentType, Grid, Input, Label, ModalLoader, getMsalAccount, useMsal } from '@gcl/react-core';
import { routes } from '@routes/routes.models';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { IBoreholeLogDraft, IGetDraftPointsRequest } from './agsAdmin.models';
import { useBatchApproveAGS, useBatchDeleteDraftAGS, useGetDraftPoints } from './agsAdmin.services';
import { AgsContext } from './AgsAdminLayout';
import { AgsTable } from './AgsTable';

export interface IAgsApprovalProps {}

export const AgsApproval: React.FC<IAgsApprovalProps> = () => {
  const agsContext = React.useContext(AgsContext);
  const jobNo = new URLSearchParams(useLocation().search)?.get('jobNo');

  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = getMsalAccount(instance);
  const appConfigs = useAppConfigs();
  React.useEffect(() => {
    if (!appConfigs.configs.approvers) return;
    const isApprover = appConfigs.configs.approvers?.includes(account?.username || '');
    if (!isApprover) navigate(routes.common.noAccessRight.path);
  }, [JSON.stringify(appConfigs.configs)]);

  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [jobNum, setJobNum] = React.useState<string | undefined>(jobNo ?? undefined);
  const [bhnum, setBhnum] = React.useState<string>();
  const [searchFilter, setSearchFilter] = React.useState<IGetDraftPointsRequest>({ jobNum, bhnum });

  const onChangeSearchJobNo = (value: string | number | undefined) => {
    setJobNum(value?.toString());
  };
  const onChangeSearchBhNo = (value: string | number | undefined) => {
    setBhnum(value?.toString());
  };

  const notifyOnChangeSelectedRows = (rows: string[]) => {
    setSelectedRows(rows);
  };

  React.useEffect(() => {
    return () => {
      agsContext?.onChangeLastProject(undefined);
    };
  }, []);

  const {
    data: dataGetDraftPoints,
    isFetching: isLoadingGetDraftPoints,
    isSuccess: isSuccessGetDraftPoints,
    error: errorGetDraftPoints,
  } = useGetDraftPoints(searchFilter ?? {});

  const onClickSearch = () => {
    setSearchFilter({ jobNum, bhnum });
  };

  const {
    data: dataBatchDeleteDraftAGS,
    isPending: isLoadingBatchDeleteDraftAGS,
    isSuccess: isSuccessBatchDeleteDraftAGS,
    error: errorBatchDeleteDraftAGS,
    reset: resetBatchDeleteDraftAGS,
    mutate: mutateBatchDeleteDraftAGS,
  } = useBatchDeleteDraftAGS();

  const {
    data: dataBatchApproveAGS,
    isPending: isLoadingBatchApproveAGS,
    isSuccess: isSuccessBatchApproveAGS,
    error: errorBatchApproveAGS,
    reset: resetBatchApproveAGS,
    mutate: mutateBatchApproveAGS,
  } = useBatchApproveAGS();

  const onClickBatchDelete = () => {
    const seletedItems = dataGetDraftPoints?.items?.filter((item) => selectedRows.includes(item.id));
    if (selectedRows === undefined || selectedRows?.length === 0) return;
    mutateBatchDeleteDraftAGS(seletedItems as IBoreholeLogDraft[]);
  };

  const onClickBatchApprove = () => {
    const seletedItems = dataGetDraftPoints?.items?.filter((item) => selectedRows.includes(item.id));
    if (selectedRows === undefined || selectedRows?.length === 0) return;
    mutateBatchApproveAGS(seletedItems as IBoreholeLogDraft[]);
  };

  return (
    <Grid container>
      <ModalLoader active={isLoadingGetDraftPoints || isLoadingBatchDeleteDraftAGS || isLoadingBatchApproveAGS} />
      <ApiErrorDisplay
        errors={[
          {
            error: errorGetDraftPoints as any,
            genericError: 'Search AGS failed',
          },
          {
            error: errorBatchDeleteDraftAGS as any,
            genericError: 'Delete AGS failed',
            reset: resetBatchDeleteDraftAGS,
          },
          {
            error: errorBatchApproveAGS as any,
            genericError: 'Approve AGS failed',
            reset: resetBatchApproveAGS,
          },
        ]}
      />
      <ApiSuccessToast
        successes={[
          {
            isSuccess: isSuccessBatchDeleteDraftAGS,
            message: 'Delete AGS Success',
            reset: resetBatchDeleteDraftAGS,
          },
          {
            isSuccess: isSuccessBatchApproveAGS,
            message: 'Approve AGS Success',
            reset: resetBatchApproveAGS,
          },
        ]}
      />
      <Grid item container flexWrap="nowrap" alignItems="center" columnGap={1}>
        <Label labelFor="searchJobNo">Job Code/No</Label>
        <Grid item flex="1 1 200px">
          <Input id="searchJobNo" onChange={onChangeSearchJobNo} />
        </Grid>
        <Label labelFor="searchBhNo">BoreHole Log</Label>
        <Grid item flex="1 1 200px">
          <Input id="searchBhNo" onChange={onChangeSearchBhNo} />
        </Grid>
        <Button
          disabled={!jobNum && !bhnum}
          className="gcl-ml-xxs gcl-clickable"
          type={ComponentType.Primary}
          onClick={onClickSearch}
        >
          Search
        </Button>
        <Button
          disabled={selectedRows.length === 0}
          className="gcl-clickable"
          type={ComponentType.Danger}
          onClick={onClickBatchDelete}
        >
          Delete
        </Button>
        <Button
          disabled={selectedRows.length === 0}
          className="gcl-clickable"
          type={ComponentType.Success}
          onClick={onClickBatchApprove}
        >
          Approve
        </Button>
      </Grid>
      <Grid item flex="1 1 100%" className="gcl-pt-sm">
        <AgsTable
          isEditable
          items={dataGetDraftPoints?.items ?? []}
          notifyOnChangeSelectedRows={notifyOnChangeSelectedRows}
        />
      </Grid>
    </Grid>
  );
};
