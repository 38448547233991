export const ENV_LOCAL = 'LOCAL';
export const ENV_DEV = 'DEV';
export const ENV_PROD = 'PROD';

export const AXIOS_API_SERVER = 'AXIOS_API_SERVER';
export const DEFAULT_SELECT_PAGE_SIZE = 20;
export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const DEFAULT_TABLE_PAGE_SIZE_OPTIONS = [
  { key: 25, text: 25, data: 25 },
  { key: 50, text: 50, data: 50 },
  { key: 100, text: 100, data: 100 },
  { key: 200, text: 200, data: 200 },
  { key: 500, text: 500, data: 500 },
];

export const DEFAULT_OPTION_ID_FIELD_NAME = 'id';
export const DEFAULT_OPTION_TEXT_FIELD_NAME = 'name';

export const FETCH_QUERIES_NAME = {
  GET_DRAFT_POINTS: 'getDraftPoints',
  SEARCH_BOREHOLE_LOG: 'searchBoreholeLog',
};

export const MUTATION_KEYS = {
  UPLOAD_AGS: 'uploadAgs',
  UPLOAD_PDFS: 'uploadPdfs',
  GET_DRAFT_POINTS: 'getDraftPoints',
  UPDATE_BOREHOLELOG: 'updateBoreholeLog',
  UPDATE_BOREHOLELOG_APPROV: 'updateBoreholeLogAndApprov',
  BATCH_DELETE_DRAFT_AGS: 'batchDeleteDraftAGS',
  BATCH_APPROVE_AGS: 'batchApproveAGS',
};
