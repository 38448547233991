import * as React from 'react';
/* eslint global-require: 0 */
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useFormData } from '../form-api';
import './BaseMap.scss';
import { MapEditor } from './Editor/Editor';
import { PinPopup } from './PinPopup/PinPopup';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const BaseMap = ({ popupRefs }: { popupRefs: React.MutableRefObject<any> }) => {
  const { response } = useFormData();
  return (
    <MapContainer
      center={[22.369126397545887, 114.17953491210938]} // Lat long for Hong Kong
      zoom={11}
      scrollWheelZoom
      className="borehole-map"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MapEditor />

      {response?.boreholes !== undefined &&
        response?.boreholes.map((borehole, index) => (
          <Marker
            key={borehole.id}
            position={[borehole.latitude, borehole.longitude]}
            ref={(el) => {
              popupRefs.current[borehole.id] = el;
            }}
          >
            <Popup>
              <PinPopup borehole={borehole} />
            </Popup>
          </Marker>
        ))}
    </MapContainer>
  );
};
