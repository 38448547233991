import { useSearchBoreholes } from '@components/borehole.services';
import { ModalLoader } from '@gcl/react-core';
import * as React from 'react';
import { useFormAPI, useFormData } from '../../form-api';
import './JobSite.css';

interface ISearchJobSite {}

export const SearchJobSite: React.FC<ISearchJobSite> = () => {
  const { onJobNumberChange, onJobNumberSearch, updateFormData } = useFormAPI();
  const { jobNumber } = useFormData();
  const [jobNumberFilter, setJobNumberFilter] = React.useState<string>();
  // Introduce a state to force re-render
  const [key, setKey] = React.useState<number>(0);
  const { data, isLoading } = useSearchBoreholes({ jobNumber: jobNumberFilter }, key);

  React.useEffect(() => {
    if (!data) return;
    updateFormData(data.data);
  }, [JSON.stringify(data)]);

  const jobNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('jobNumberChanged', e.target?.value);
    onJobNumberChange(e.target?.value);
  };

  const onSearchClicked = () => {
    console.log('jobNumber', 'onSearchClick', jobNumber);
    setJobNumberFilter(jobNumber);
    setKey((prevKey) => prevKey + 1);
  };

  const onClearClicked = () => {
    console.log('jobNumber', 'onClearClicked', jobNumber);
    onJobNumberChange('');
  };

  return (
    <form className="">
      <ModalLoader active={isLoading} />
      <label className="form-label" htmlFor="formJobNumber">
        Job Num:
      </label>
      <input
        id="formJobNumber"
        type="text"
        placeholder="Input and press enter"
        value={jobNumber ?? ''}
        onChange={jobNumberChanged}
        className="form-control gcl-mb-xs"
      />
      <input type="button" value="Search" className="btn btn-primary gcl-mr-xs" onClick={onSearchClicked} />
      <input type="button" value="Clear" className="btn btn-secondary" onClick={onClearClicked} />
    </form>
  );
};
