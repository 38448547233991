import * as React from 'react';
import { Button, ComponentType, PageNotFound as PageNotFoundBase } from '@gcl/react-core';
import { routes } from '@routes/routes.models';
import { useNavigate } from 'react-router-dom';

interface IPageNotFoundProps {
  header?: string;
  message?: string;
}

const PageNotFound: React.FunctionComponent<IPageNotFoundProps> = () => {
  const navigate = useNavigate();
  return (
    <div className="gcl-p-sm">
      <PageNotFoundBase
        onRenderBackHomeButton={(onClickBackHome: () => void, backHomeButtonText: string) => {
          return (
            <Button
              type={ComponentType.Primary}
              onClick={() => {
                navigate(routes.home.path);
              }}
            >
              {backHomeButtonText}
            </Button>
          );
        }}
      />
    </div>
  );
};

export default PageNotFound;
