import { Button, ComponentType } from '@gcl/react-core';
import * as React from 'react';

export interface IReportErrorButtonProps {
  traceId: string;
  errorMessage: string;
}

export const ReportErrorButton: React.FC<IReportErrorButtonProps> = ({ traceId, errorMessage }) => {
  const helpDeskBaseUrl = 'https://srms.gammonconstruction.com/#/inquiry/GEMS/GEMS';
  return (
    <Button
      type={ComponentType.Danger}
      role="link"
      onClick={() => {
        window.open(
          `${helpDeskBaseUrl}?description=Description: (%23%23%23Please input%23%23%23), TraceId: ${traceId}, Error: ${errorMessage}`,
          '_blank',
          'noreferrer',
        );
      }}
    >
      Report
    </Button>
  );
};
