/* eslint-disable @typescript-eslint/no-explicit-any */
import { useErrorDisplay, IReactBaseProps } from '@gcl/react-core';

import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IAppConfigContext, AppConfigs } from './config.models';
import { IEnvConfigs, envConfigs } from './envConfigs';

const traceId = uuidv4();

export interface IAppConfig extends IEnvConfigs {
  isAnyActionLoading?: boolean;
}

export const AppConfigContext = React.createContext<IAppConfigContext<IAppConfig>>({
  configs: { traceId, ...envConfigs },
  updateConfigs: () => {},
});

export interface IAppConfigProps extends IReactBaseProps {}

export const AppConfig: React.FunctionComponent<IAppConfigProps> = ({ children }) => {
  const configErrors = Object.entries(envConfigs).reduce((results: any[], [key, value]) => {
    if (value === undefined)
      results.push({
        name: 'App config error',
        message: `${key} is missing`,
      });
    return results;
  }, []);
  useErrorDisplay(configErrors.length > 0 ? configErrors : undefined);
  const [appConfigs, setAppConfigs] = React.useState<AppConfigs<IAppConfig>>({
    traceId,
    ...envConfigs,
  });

  const defaultAppConfigs = React.useMemo(
    () => ({ configs: appConfigs, updateConfigs: setAppConfigs }),
    [JSON.stringify(appConfigs)],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppConfigContext.Provider value={defaultAppConfigs}>{children}</AppConfigContext.Provider>
  );
};
