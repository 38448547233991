import { AXIOS_API_SERVER } from '@common/constants';
import { envConfigs } from '@config/envConfigs';
import { getAxiosApiInstanceByName } from '@gcl/react-core';
import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import { Polygon } from '../models/polygon';
import { SearchBoreholeRequest } from '../models/searchBoreholeRequest';
import { SearchBoreholeResponse } from '../models/searchBoreholeResponse';

type State = {
  jobNumber?: string;
  keyword?: string;
  polygon?: Polygon;
  response?: SearchBoreholeResponse;
};

type API = {
  onJobNumberChange: (jobNumber: string) => void;
  onKeywordChange: (keyword: string) => void;
  onPolygonChange: (polygon: Polygon) => void;
  onKeywordSearch: (keyword?: string) => void;
  onJobNumberSearch: (jobNumber?: string) => void;
  onPolygonSearch: (polygon?: Polygon) => void;
  onPolygonDelete: () => void;
  updateFormData: (response: SearchBoreholeResponse) => void;
};

const FormDataContext = createContext<State>({} as State);
const FormAPIContext = createContext<API>({} as API);

type Actions =
  | { type: 'updateJobNumber'; jobNumber: string }
  | { type: 'updateKeyword'; keyword: string }
  | { type: 'updatePolygon'; polygon: Polygon }
  | { type: 'updateResponse'; response: SearchBoreholeResponse };

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'updateJobNumber': {
      console.warn('reducer', 'updateJobNumber', action.jobNumber);
      return { ...state, jobNumber: action.jobNumber };
    }
    case 'updateKeyword':
      return { ...state, keyword: action.keyword };
    case 'updatePolygon': {
      console.warn('reducer', 'updatePolygon', action.polygon);
      return { ...state, polygon: action.polygon };
    }
    case 'updateResponse':
      return { ...state, response: action.response };
    default:
      throw new Error('Unknown action type');
  }
};

export const FormDataProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {} as State);
  const searchBoreholes = async (request: SearchBoreholeRequest) => {
    console.log('searchBoreholes', 'api request', request);
    await getAxiosApiInstanceByName(AXIOS_API_SERVER)
      .post<SearchBoreholeResponse>(`${envConfigs.apiBaseUrl}/BoreholeLog/Search`, request)
      .then((res) => {
        console.log('searchBoreholes', 'api response', res);
        const response: SearchBoreholeResponse = { ...res.data };
        dispatch({ type: 'updateResponse', response });
      })
      .catch((err) => {
        console.error(err);
        if (err.response) {
          if (err.response.status === 404) {
            console.error('HTTP 404 Handles with care');
            const response: SearchBoreholeResponse = {};
            dispatch({ type: 'updateResponse', response });
          }
        }
      });
  };

  const api = useMemo(() => {
    const onJobNumberSearch = (jobNumber?: string) => {
      console.log('onJobNumberSearch', state, state.jobNumber);
      const request: SearchBoreholeRequest = {
        jobNumber,
      };
      searchBoreholes(request);
    };

    const onKeywordSearch = (keyword?: string) => {
      console.log('onKeywordSearch', state.keyword);
      const request: SearchBoreholeRequest = {
        keyword,
      };
      searchBoreholes(request);
    };

    const onPolygonSearch = (polygon?: Polygon) => {
      console.log('onPolygonSearch', state.polygon);
      const request: SearchBoreholeRequest = {
        polygon,
      };
      searchBoreholes(request);
    };

    const onJobNumberChange = (jobNumber: string) => {
      dispatch({ type: 'updateJobNumber', jobNumber });
    };

    const onKeywordChange = (keyword: string) => {
      dispatch({ type: 'updateKeyword', keyword });
    };

    const onPolygonChange = (polygon: Polygon) => {
      console.warn('onPolygonChange', polygon);
      dispatch({ type: 'updatePolygon', polygon });
    };

    const onPolygonDelete = () => {
      console.warn('onPolygonDelete');

      // Dispatch an empty object
      const response: SearchBoreholeResponse = {};
      dispatch({ type: 'updateResponse', response });
    };

    const updateFormData = (response: SearchBoreholeResponse) => {
      dispatch({ type: 'updateResponse', response });
    };

    return {
      onJobNumberSearch,
      onKeywordSearch,
      onPolygonSearch,
      onJobNumberChange,
      onKeywordChange,
      onPolygonChange,
      onPolygonDelete,
      updateFormData,
    };
  }, []);

  return (
    <FormAPIContext.Provider value={api}>
      <FormDataContext.Provider value={state}>{children}</FormDataContext.Provider>
    </FormAPIContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
export const useFormAPI = () => useContext(FormAPIContext);
