import { AboutMe } from '../../Me/AboutMe';
import './AdminPage.css';

export const AdminPage = () => {
  return (
    <div>
      <p>This is admin page</p>
      <AboutMe />
    </div>
  );
};
