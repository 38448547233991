import { Note } from '@core/Note/Note';
import {
  appendClassNameByCondition,
  ComponentType,
  Icon,
  IconName,
  IReactBaseProps,
  Label,
  Tooltip,
} from '@gcl/react-core';
import * as React from 'react';
import { ReactElement } from 'react';
import './LabelWithMessage.scss';

export interface ILabelWithMessageProps extends IReactBaseProps {
  labelFor: string;
  message?: string | ReactElement;
  text: string;
  alwaysOn?: boolean;
  required?: boolean;
  componentType?: ComponentType;
  iconName?: IconName;
  iconWithColor?: boolean;
  className?: string;
  testId?: string;
}

export const LabelWithMessage: React.FunctionComponent<ILabelWithMessageProps> = ({
  text,
  labelFor,
  message,
  alwaysOn = false,
  required = false,
  componentType = ComponentType.Info,
  iconName,
  iconWithColor = false,
  className,
  testId,
}) => {
  const popover = React.useMemo(() => {
    if (alwaysOn || !message || (typeof message === 'string' && message.trim() === '')) return <></>;
    return (
      <Tooltip id={`${labelFor}_label_tooltip`} key={`${labelFor}_label_tooltip_key`} overlay={<>{message}</>}>
        <Icon
          iconName={iconName || 'QuestionCircle'}
          className="gcl-ml-sm gcl-ic-lg"
          componentType={iconWithColor ? componentType : undefined}
        />
      </Tooltip>
    );
  }, [alwaysOn, message]);

  const note = React.useMemo(() => {
    if (!alwaysOn || !message || (typeof message === 'string' && message.trim() === '')) return <></>;
    return (
      <Note
        componentType={componentType}
        prefixIcon={<Icon iconName={iconName || 'InfoCircle'} />}
        className="gcl-mb-sm"
      >
        {message}
      </Note>
    );
  }, [alwaysOn, message, componentType, iconName]);

  return (
    <div>
      <div
        className={appendClassNameByCondition(!!className, 'gcl-borehole-log-label-with-message gcl-mb-xs', className)}
        key={`${labelFor}_label_container`}
      >
        <Label
          data-testid={testId}
          labelFor={labelFor}
          className="gcl-borehole-log-label-with-message-label"
          required={required}
        >
          <div>{text}</div>
        </Label>
        {popover}
      </div>
      {note}
    </div>
  );
};
