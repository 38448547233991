import { IErrorResponse } from '@common/api.models';
import { AXIOS_API_SERVER, FETCH_QUERIES_NAME, MUTATION_KEYS } from '@common/constants';
import { envConfigs } from '@config/envConfigs';
import { IListResponse, getAxiosApiInstanceByName } from '@gcl/react-core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IBoreholeLogDraft, IGetDraftPointsRequest } from './agsAdmin.models';

export const apiRoutes = {
  uploadXlsx: '/BoreholeLog/ExcelImport',
  uploadAgs: '/BoreholeLog/AgsImport',
  uploadPdf: '/BoreholeLog/UploadPdf',
  getDraftPoints: '/BoreholeLog/GetDraftPointsByJobNum',
  updateBoreholeLog: '/BoreholeLog/UpdateBoreholeLog',
  updateBoreholeLogAndApprov: '/BoreholeLog/UpdateBoreholeAndApprov',
  batchDeleteDraftAGS: '/BoreholeLog/BatchDeleteDraftAGS',
  batchApproveAGS: '/BoreholeLog/BatchApproveAGS',
};

export const useUploadPdfs = () => {
  const queryClient = useQueryClient();
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, File[], any>({
    mutationKey: [MUTATION_KEYS.UPLOAD_PDFS],
    mutationFn: async (files: File[]) => {
      const result = await uploadPdfsAsync(files);
      return result;
    },
    onSettled: async (data) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], data?.items);
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const uploadPdfsAsync = async (files: File[]) => {
  const form = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    form.append('files', files[i]);
  }
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.uploadPdf}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

export const useUploadXlsx = () => {
  const queryClient = useQueryClient();
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, File, any>({
    mutationKey: [MUTATION_KEYS.UPLOAD_AGS],
    mutationFn: async (file: File) => {
      const result = await uploadXlsxAsync(file);
      return result;
    },
    onSettled: async (data) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], data?.items);
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const uploadXlsxAsync = async (file: File) => {
  const form = new FormData();
  form.append('file', file);
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.uploadXlsx}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

export const useUploadAgs = () => {
  const queryClient = useQueryClient();
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, File, any>({
    mutationKey: [MUTATION_KEYS.UPLOAD_AGS],
    mutationFn: async (file: File) => {
      const result = await uploadAgsAsync(file);
      return result;
    },
    onSettled: async (data) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], data?.items);
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const uploadAgsAsync = async (file: File) => {
  const form = new FormData();
  form.append('file', file);
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.uploadAgs}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

export const useGetDraftPoints = (request: IGetDraftPointsRequest) => {
  return useQuery({
    queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS, request],
    queryFn: async () => {
      return getDraftPointsAsync(request);
    },
    enabled: request.jobNum !== undefined || request.bhnum !== undefined,
  });
};

export const useMutateGetDraftPoints = () => {
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, IGetDraftPointsRequest, any>({
    mutationKey: [MUTATION_KEYS.GET_DRAFT_POINTS],
    mutationFn: async (request: IGetDraftPointsRequest) => {
      const result = await getDraftPointsAsync(request);
      return result;
    },
  });
};

export const getDraftPointsAsync = async (request: IGetDraftPointsRequest) => {
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.getDraftPoints}`,
    undefined,
    { params: request },
  );
  return result.data;
};

export const useUpdateBoreholeLog = () => {
  const queryClient = useQueryClient();
  return useMutation<IBoreholeLogDraft, AxiosError<IErrorResponse, any>, IBoreholeLogDraft, any>({
    mutationKey: [MUTATION_KEYS.UPDATE_BOREHOLELOG],
    mutationFn: async (request: IBoreholeLogDraft) => {
      const result = await updateBoreholeLogAsync(request);
      return result;
    },
    onMutate: async (updatedItem) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });

      const previousItems = queryClient.getQueryData<IBoreholeLogDraft[]>([FETCH_QUERIES_NAME.GET_DRAFT_POINTS]);
      const newItems = previousItems?.map((item: IBoreholeLogDraft) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], newItems);
      return { previousItems };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const updateBoreholeLogAsync = async (request: IBoreholeLogDraft) => {
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IBoreholeLogDraft>(
    `${envConfigs.apiBaseUrl}${apiRoutes.updateBoreholeLog}`,
    request,
  );
  return result.data;
};

export const useUpdateBoreholeLogAndApprov = () => {
  return useMutation<IBoreholeLogDraft, AxiosError<IErrorResponse, any>, IBoreholeLogDraft, any>({
    mutationKey: [MUTATION_KEYS.UPDATE_BOREHOLELOG_APPROV],
    mutationFn: async (request: IBoreholeLogDraft) => {
      const result = await updateBoreholeLogAndApprovAsync(request);
      return result;
    },
  });
};

export const updateBoreholeLogAndApprovAsync = async (request: IBoreholeLogDraft) => {
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IBoreholeLogDraft>(
    `${envConfigs.apiBaseUrl}${apiRoutes.updateBoreholeLogAndApprov}`,
    request,
  );
  return result.data;
};

export const useBatchDeleteDraftAGS = () => {
  const queryClient = useQueryClient();
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, IBoreholeLogDraft[], any>({
    mutationKey: [MUTATION_KEYS.BATCH_DELETE_DRAFT_AGS],
    mutationFn: async (request: IBoreholeLogDraft[]) => {
      const result = await batchDeleteDraftAGSAsync(request);
      return result;
    },
    onSettled: async (data) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], data?.items);
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const batchDeleteDraftAGSAsync = async (request: IBoreholeLogDraft[]) => {
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.batchDeleteDraftAGS}`,
    request,
  );
  return result.data;
};

export const useBatchApproveAGS = () => {
  const queryClient = useQueryClient();
  return useMutation<IListResponse<IBoreholeLogDraft>, AxiosError<IErrorResponse, any>, IBoreholeLogDraft[], any>({
    mutationKey: [MUTATION_KEYS.BATCH_APPROVE_AGS],
    mutationFn: async (request: IBoreholeLogDraft[]) => {
      const result = await batchApproveAGSAsync(request);
      return result;
    },
    onSettled: async (data) => {
      await queryClient.cancelQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
      queryClient.setQueryData([FETCH_QUERIES_NAME.GET_DRAFT_POINTS], data?.items);
      queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
    },
  });
};

export const batchApproveAGSAsync = async (request: IBoreholeLogDraft[]) => {
  const result = await getAxiosApiInstanceByName(AXIOS_API_SERVER).post<IListResponse<IBoreholeLogDraft>>(
    `${envConfigs.apiBaseUrl}${apiRoutes.batchApproveAGS}`,
    request,
  );
  return result.data;
};
