import { useAppConfigs } from '@config/config.hooks';
import { envConfigs } from '@config/envConfigs';
import {
  Avatar,
  AzureLoginLogoutButton,
  Button,
  ComponentType,
  Grid,
  Heading,
  HeadingType,
  ILoggedInUser,
  Icon,
} from '@gcl/react-core';

export const TopNavBar = () => {
  const appConfigs = useAppConfigs();

  return (
    <div className="gcl-borehole-log-top-nav-container">
      <AzureLoginLogoutButton
        scopes={[envConfigs.apiScope as string]}
        onUserLoggedIn={(user: ILoggedInUser) => {
          appConfigs.updateConfigs?.((configs) => {
            return { ...configs, loggedInUser: user };
          });
        }}
        validateIssuers={() => {
          // if (!envConfigs?.validIssuers) return true;
          // return envConfigs.validIssuers.split(',').some((validIssuer) => {
          //   // issuer format 'https://login.microsoftonline.com/{tenantId}/v2.0
          //   return issuer.includes(validIssuer);
          // });
          return true;
        }}
        render={(isLoggedIn, onClickLoginLogoutBtn, accounInfo, imageSrc) => {
          return (
            <>
              <div className="gcl-borehole-log-layout-top-nav-logo">
                <img alt="gammon-logo" src="/images/gammon-logo.png" className="gcl-mr-sm" />
                <Heading as={HeadingType.h1} styledAs={HeadingType.h5}>
                  Borehole log
                </Heading>
              </div>
              <Grid flexDirection="row" justifyContent="flex-end" container alignItems="center">
                {isLoggedIn ? (
                  <>
                    <div className="gcl-mr-sm">
                      <Avatar round name={accounInfo?.name || ''} maxInitials={1} size="28" src={imageSrc} />
                    </div>

                    <div>
                      <Icon
                        iconName="BoxArrowRight"
                        className="gcl-clickable  "
                        size={20}
                        onClick={onClickLoginLogoutBtn}
                      />
                    </div>
                  </>
                ) : (
                  <Button
                    style={{ margin: '0 auto 10px auto' }}
                    className="gcl-clickable"
                    onClick={onClickLoginLogoutBtn}
                    type={ComponentType.Primary}
                    prefixIconName="Microsoft"
                  >
                    <span>Sign In</span>
                  </Button>
                )}
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
};
