import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BaseMap } from '../../Map/BaseMap';
import { SearchPanel } from '../../Search/SearchPanel';

import './HomePage.css';

interface IHomePage {}

export const HomePage: React.FC<IHomePage> = () => {
  const popupRefs = React.useRef<any>({});

  const handleOpenPopup = (boreholeId: string) => {
    if (popupRefs.current[boreholeId]) {
      const popup: any = popupRefs.current[boreholeId];
      console.log('popup', popup);
      if (popup.isPopupOpen()) popup.closePopup();
      else popup.openPopup();
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4} xl={7}>
          <SearchPanel handleOpenPopup={handleOpenPopup} />
        </Col>
        <Col>
          <div className="protected-app">
            <BaseMap popupRefs={popupRefs} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
