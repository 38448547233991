import { useAppConfigs } from '@config/config.hooks';
import { envConfigs } from '@config/envConfigs';
import { AzureLoginLogoutButton, Button, ComponentType, ILoggedInUser } from '@gcl/react-core';
import { routes } from '@routes/routes.models';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './requireLogin.scss';

export interface IRequireLoginProps {}

export const RequireLogin: React.FC<IRequireLoginProps> = ({}) => {
  const appConfigs = useAppConfigs();
  const navigate = useNavigate();

  return (
    <div className="gcl-borehole-log-require-login-container">
      <div className="gcl-borehole-log-require-login-content">
        <div className="gcl-borehole-log-require-login-logo">
          <img alt="gammon-logo" src="/images/gammon-logo.png" className="gcl-mr-sm" />
        </div>
        <div className="gcl-borehole-log-require-login-text">
          <b className="gcl-fs-md">Login now to start using </b>
          <br /> Borehole Log
        </div>
        <AzureLoginLogoutButton
          scopes={[envConfigs.apiScope as string]}
          onUserLoggedIn={(user: ILoggedInUser) => {
            appConfigs.updateConfigs?.((configs) => {
              return { ...configs, loggedInUser: user };
            });

            navigate(routes.home.path);
          }}
          validateIssuers={(issuer) => {
            if (!envConfigs?.validIssuers) return true;
            return envConfigs.validIssuers.split(',').some((validIssuer) => {
              // issuer format 'https://login.microsoftonline.com/{tenantId}/v2.0
              return issuer.includes(validIssuer);
            });
          }}
          render={(isLoggedIn, onClickLoginLogoutBtn) => {
            return !isLoggedIn ? (
              <Button
                style={{ margin: '0 auto 10px auto' }}
                className="gcl-clickable"
                onClick={onClickLoginLogoutBtn}
                type={ComponentType.Primary}
                prefixIconName="Microsoft"
              >
                <span>Sign In</span>
              </Button>
            ) : null;
          }}
        />
      </div>
    </div>
  );
};
