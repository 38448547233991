import * as React from 'react';
import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';

import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AgsAdminLayout } from '@agsAdmin/AgsAdminLayout';
import { AgsApproval } from '@agsAdmin/AgsApproval';
import { AgsUpload } from '@agsAdmin/AgsUpload';
import { Layout } from '@app/Layout';
import { CustomAxiosConfig } from '@common/CustomAxiosConfig';
import { NoAccessRight } from '@common/NoAccessRight';
import PageNotFound from '@common/PageNotFound';
import { RequireLogin } from '@common/RequireLogin';
import { LogginErrorBoundary } from '@common/error/LogginErrorBoundary';
import { AdminPage } from '@components/Pages/Admin/AdminPage';
import { HomePage } from '@components/Pages/Home/HomePage';
import { ErrorBoundary } from 'react-error-boundary';
import { ProtectedRoute } from './ProtectedRoute';
import { routes } from './routes.models';

export interface IRoutesProps {}

export const Routes: React.FC<IRoutesProps> = ({}) => {
  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: qs.parse,
          objectToSearchString: qs.stringify,
        }}
      >
        <ErrorBoundary FallbackComponent={LogginErrorBoundary}>
          <CustomAxiosConfig />
        </ErrorBoundary>

        <ReactRoutes>
          <Route path={routes.home.path} element={<Layout />}>
            <Route path={routes.common.pageNotFound.path} element={<PageNotFound />} />
            <Route path={routes.common.requireLogin.path} element={<RequireLogin />} />
            <Route path={routes.common.noAccessRight.path} element={<NoAccessRight />} />
            <Route path={routes.home.path} element={<ProtectedRoute element={<HomePage />} />} />
            <Route path={routes.common.admin.path} element={<ProtectedRoute element={<AdminPage />} />} />
            <Route path={routes.common.ags.path} element={<ProtectedRoute element={<AgsAdminLayout />} />}>
              <Route index element={<ProtectedRoute element={<AgsUpload />} />} />
              <Route index path={routes.common.ags.upload.path} element={<ProtectedRoute element={<AgsUpload />} />} />
              <Route path={routes.common.ags.approve.path} element={<ProtectedRoute element={<AgsApproval />} />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </ReactRoutes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
