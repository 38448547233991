import { AXIOS_API_SERVER, FETCH_QUERIES_NAME } from '@common/constants';
import { envConfigs } from '@config/envConfigs';
import { getAxiosApiInstanceByName } from '@gcl/react-core';
import { SearchBoreholeRequest } from '@models/searchBoreholeRequest';
import { SearchBoreholeResponse } from '@models/searchBoreholeResponse';
import { useQuery } from '@tanstack/react-query';

export const apiRoutes = {
  searchBoreholeLog: '/BoreholeLog/Search',
};

export const useSearchBoreholes = (request: SearchBoreholeRequest, key: number = 0) => {
  return useQuery({
    queryKey: [FETCH_QUERIES_NAME.SEARCH_BOREHOLE_LOG, request, key],
    queryFn: async () => {
      return searchBoreholesAsync(request);
    },
    enabled: request.jobNumber !== undefined || request.keyword !== undefined || request.polygon !== undefined,
  });
};

export const searchBoreholesAsync = async (request: SearchBoreholeRequest) => {
  return getAxiosApiInstanceByName(AXIOS_API_SERVER).post<SearchBoreholeResponse>(
    `${envConfigs.apiBaseUrl}${apiRoutes.searchBoreholeLog}`,
    request,
  );
};
