import * as React from 'react';

export const reactAppPrefix = 'REACT_APP_';

export type AppConfigs<T> = T & {
  traceId: string;
  loggedInUser?: {
    id: string | undefined;
    displayName: string | undefined;
  };
};

export interface IAppConfigContext<T> {
  configs: AppConfigs<T>;
  updateConfigs: React.Dispatch<React.SetStateAction<AppConfigs<T>>> | undefined;
}
