import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Boreholes } from './Boreholes/Boreholes';
import { SearchFreeText } from './FreeText/FreeText';
import { SearchJobSite } from './JobSite/JobSite';

interface ISearchPanel {
  handleOpenPopup: (boreholeId: string) => void;
}

export const SearchPanel: React.FC<ISearchPanel> = ({ handleOpenPopup }) => {
  return (
    <>
      <Tabs className="search-tabs">
        <Tab eventKey="jobSite" title="Jobsite">
          <SearchJobSite />
        </Tab>
        <Tab eventKey="freeText" title="Free text">
          <SearchFreeText />
        </Tab>
      </Tabs>

      <Boreholes handleOpenPopup={handleOpenPopup} />
    </>
  );
};
