import { toastMessage, ToastMessage } from '@gcl/react-core';
import * as React from 'react';

export interface IApiSuccessToastProps {
  successes: { isSuccess: boolean; message: string; reset?: (() => void) | undefined }[];
  callbackAfterToasted?: () => void;
}

export const ApiSuccessToast: React.FC<IApiSuccessToastProps> = ({ successes = [], callbackAfterToasted }) => {
  React.useEffect(() => {
    successes.forEach(({ isSuccess, message, reset }) => {
      if (!isSuccess) return;
      toastMessage(
        <ToastMessage message={message} toastOptions={{ type: 'success', closeOnClick: true, autoClose: 3000 }} />,
      );
      if (reset && typeof reset === 'function') reset();
      if (callbackAfterToasted && typeof callbackAfterToasted === 'function') callbackAfterToasted();
    });
  }, [JSON.stringify(successes)]);

  return <></>;
};
