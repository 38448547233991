import * as React from 'react';
import { AzureAdAxiosConfig, IReactBaseProps } from '@gcl/react-core';
import { envConfigs } from '@config/envConfigs';
import { useAppConfigs } from '@config/config.hooks';
import { AXIOS_API_SERVER } from './constants';

export interface ICustomAxiosConfigProps extends IReactBaseProps {}

export const CustomAxiosConfig: React.FC<ICustomAxiosConfigProps> = ({ children }) => {
  const appConfigs = useAppConfigs();
  return (
    <AzureAdAxiosConfig
      azureAdAxiosInterceptors={[
        {
          traceId: appConfigs.configs.traceId,
          scopes: [envConfigs.apiScope as string],
          name: AXIOS_API_SERVER,
          configs: { baseURL: envConfigs.apiBaseUrl as string },
        },
      ]}
    >
      {children}
    </AzureAdAxiosConfig>
  );
};
