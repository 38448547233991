import axios from 'axios';
import { useEffect, useState } from 'react';

export const AboutMe = () => {
  const [aboutMe, setAboutMe] = useState<string>();
  const [authorized, setAuthorized] = useState<boolean>();

  useEffect(() => {
    axios
      .get('identity/me/')
      .then((res) => {
        setAboutMe(JSON.stringify(res, undefined, 2));
        setAuthorized(true);
      })
      .catch((err) => {
        if (err.response.status === 401) setAuthorized(false);
        // console.error(err);
      });
  }, []);

  return (
    <div className="about-me">
      {authorized ? <pre>{aboutMe}</pre> : <p className="unauthorized">HTTP 401 Unauthorized</p>}
    </div>
  );
};
