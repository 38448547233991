import { ApiErrorDisplay } from '@core/ApiErrorDisplay';
import { ApiSuccessToast } from '@core/ApiSuccessToast';
import { Button, ComponentType, FileUploader, Grid, IFileUploaderValue, ModalLoader } from '@gcl/react-core';
import * as React from 'react';
import { useGetDraftPoints, useUploadAgs, useUploadPdfs, useUploadXlsx } from './agsAdmin.services';
import { AgsContext } from './AgsAdminLayout';
import { AgsTable } from './AgsTable';

export interface IAgsUploadProps {}

export const AgsUpload: React.FC<IAgsUploadProps> = ({}) => {
  const agsContext = React.useContext(AgsContext);
  const [xlsxUploaderValue, setXlsxUploaderValue] = React.useState<IFileUploaderValue | undefined>(undefined);
  const [agsUploaderValue, setAgsUploaderValue] = React.useState<IFileUploaderValue | undefined>(undefined);
  const onClickUploadXlsx = () => {
    if (xlsxUploaderValue) {
      mutateUploadXlsx(xlsxUploaderValue.files[0]);
    }
  };
  const onClickUploadAgs = () => {
    if (agsUploaderValue) {
      mutateUploadAgs(agsUploaderValue.files[0]);
    }
  };
  const onClickUploadPdfs = () => {
    if (pdfsUploaderValue) {
      mutateUploadPdfs(pdfsUploaderValue.files);
    }
  };
  const [pdfsUploaderValue, setPdfsUploaderValue] = React.useState<IFileUploaderValue | undefined>(undefined);

  const {
    data: dataUploadXlsx,
    isPending: isLoadingUploadXlsx,
    isSuccess: isSuccessUploadXlsx,
    error: errorUploadXlsx,
    reset: resetUploadXlsx,
    mutate: mutateUploadXlsx,
  } = useUploadXlsx();

  const {
    data: dataUploadAgs,
    isPending: isLoadingUploadAgs,
    isSuccess: isSuccessUploadAgs,
    error: errorUploadAgs,
    reset: resetUploadAgs,
    mutate: mutateUploadAgs,
  } = useUploadAgs();

  const {
    data: dataUploadPdfs,
    isPending: isLoadingUploadPdfs,
    isSuccess: isSuccessUploadPdfs,
    error: errorUploadPdfs,
    reset: resetUploadPdfs,
    mutate: mutateUploadPdfs,
  } = useUploadPdfs();

  const {
    data: dataGetDraftPoints,
    isFetching: isLoadingGetDraftPoints,
    isSuccess: isSuccessGetDraftPoints,
    error: errorGetDraftPoints,
  } = useGetDraftPoints({ jobNum: dataUploadXlsx?.items?.[0]?.bljobnum || dataUploadAgs?.items?.[0]?.bljobnum });

  React.useEffect(() => {
    if ((dataGetDraftPoints?.items?.length ?? 0) > 0) {
      agsContext.onChangeLastProject(dataGetDraftPoints?.items?.[0].bljobnum);
    }
  }, [JSON.stringify(dataGetDraftPoints)]);

  return (
    <Grid container>
      <ModalLoader
        active={isLoadingUploadXlsx || isLoadingUploadAgs || isLoadingUploadPdfs || isLoadingGetDraftPoints}
      />
      <ApiErrorDisplay
        errors={[
          {
            error: errorUploadXlsx,
            genericError: 'Upload xlsx failed',
          },
          {
            error: errorUploadAgs,
            genericError: 'Upload AGS failed',
          },
          {
            error: errorUploadPdfs,
            genericError: 'Upload PDFs failed',
          },
          {
            error: errorGetDraftPoints as any,
            genericError: 'Get Draft Points failed',
          },
        ]}
      />
      <ApiSuccessToast
        successes={[
          {
            isSuccess: isSuccessUploadPdfs,
            message: 'Update PDFs Success',
          },
        ]}
      />
      <Grid item container flexWrap="nowrap" alignItems="center">
        <Grid container>
          <Grid item flex="1 1 auto">
            <FileUploader
              id="ags"
              value={agsUploaderValue}
              onChange={(newValue) => {
                setAgsUploaderValue(newValue ?? undefined);
              }}
              acceptFileTypes={['.ags']}
            />
          </Grid>
          <Grid item flex="0 0 150px">
            <Button type={ComponentType.Primary} onClick={onClickUploadAgs} disabled={!agsUploaderValue}>
              Upload .ags file
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1 1 auto">
            <FileUploader
              id="xlsx"
              value={xlsxUploaderValue}
              onChange={(newValue) => {
                setXlsxUploaderValue(newValue ?? undefined);
              }}
              acceptFileTypes={['.xlsx', 'xls']}
            />
          </Grid>
          <Grid item flex="0 0 150px">
            <Button type={ComponentType.Primary} onClick={onClickUploadXlsx} disabled={!xlsxUploaderValue}>
              Upload .xls / .xlsx
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item flex="0 1 50%">
          <FileUploader
            className="gcl-mt-sm"
            disabled={!dataUploadXlsx && !dataUploadAgs}
            multiple
            id="files"
            value={pdfsUploaderValue}
            onChange={(newValue) => {
              setPdfsUploaderValue(newValue ?? undefined);
            }}
            acceptFileTypes={['.pdf']}
          />
        </Grid>
        <Grid item flex="0 0 120px">
          <Button
            className="gcl-mt-sm"
            type={ComponentType.Primary}
            onClick={onClickUploadPdfs}
            disabled={!pdfsUploaderValue}
          >
            Upload pdf
          </Button>
        </Grid>
      </Grid>
      <Grid item flex="1 1 100%" className="gcl-pt-sm">
        <AgsTable items={dataGetDraftPoints?.items ?? []} />
      </Grid>
    </Grid>
  );
};
