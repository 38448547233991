import { ReportErrorButton } from '@common/error/ReportErrorButton';
import { useAppConfigs } from '@config/config.hooks';
import { ErrorOption, ToastMessage, toastMessage } from '@gcl/react-core';
import { routes } from '@routes/routes.models';
import * as React from 'react';
import { useNavigate } from 'react-router';
import './ApiErrorDisplay.scss';
import { IErrorResponsePair } from './error.models';
import { apiErrorHandle } from './error.services';

export interface IApiErrorDisplayProps {
  errors: IErrorResponsePair[];
  setFormError?: (
    name: string,
    error: ErrorOption,
    options?:
      | {
          shouldFocus: boolean;
        }
      | undefined,
  ) => void;
  callbackAfterToast?: () => void;
}

export const ApiErrorDisplay: React.FC<IApiErrorDisplayProps> = ({ errors = [], setFormError, callbackAfterToast }) => {
  const appConfigs = useAppConfigs();
  const navigate = useNavigate();

  // If there are multiple response errors, only display the first one
  const error = errors.filter((e) => {
    return !!e?.error;
  })?.[0];

  React.useEffect(() => {
    if (!error?.error) return;
    const { errorType, errorMessage, formErrors } = apiErrorHandle(error?.error, error?.genericError);
    if (errorType === 'DirectDisplayError') {
      if (errorMessage) {
        toastMessage(
          <ToastMessage
            message={errorMessage}
            toastOptions={{ type: 'error', closeOnClick: true, autoClose: false }}
          />,
        );
      } else {
        showToastWithReport(error, appConfigs.configs.traceId);
      }
    }
    if (errorType === 'FormError') {
      if (formErrors && setFormError) {
        Object.keys(formErrors).forEach((key) => {
          const keyLocal = error.keyExtractor ? error.keyExtractor(key) : key;
          setFormError(keyLocal, {
            message: formErrors[key],
          });
        });
      } else if (!setFormError) {
        showToastWithReport(error, appConfigs.configs.traceId);
      }
    }
    if (error.error.response?.status === 403) {
      navigate(routes.common.noAccessRight.path);
    }

    if (error.reset) error.reset();

    if (callbackAfterToast && typeof callbackAfterToast === 'function') callbackAfterToast();
  }, [JSON.stringify(error)]);

  return <></>;
};

export const showToastWithReport = (error: IErrorResponsePair, traceId: string) => {
  toastMessage(
    <ToastMessage
      message={
        <div className="gcl-borehole-log-api-error-display-container">
          <div>{error.genericError}</div>
          <ReportErrorButton errorMessage={error.genericError} traceId={traceId} />
        </div>
      }
      toastOptions={{ type: 'error', closeOnClick: true, autoClose: false }}
    />,
  );
};
