export interface IBoreholeLogDraft {
  hasAgsFile: boolean;
  id: string;
  bljobnum: string;
  blwrkord: string;
  blbhnum: string;
  bleast: number;
  blnorth: number;
  blrmks: string;
  blngT200?: number | null;
  blG3ROCK?: number | null;
  blG2ROCK?: number | null;
  blgndlvl: number;
  regionalID: number;
  lastModified: string;
  lastModifiedBy?: string | null;
  fileName: string;
  coordGeocodePoint?: CoordGeocodePoint | null;
  coordGeocodeStatus: string;
  iD1: number;
  transformStatus: boolean;
  coordGeocodePoint_XLO?: CoordGeocodePoint | null;
  coordGeocodePoint_YLO?: CoordGeocodePoint | null;
  coordGeocodePoint_XHI?: CoordGeocodePoint | null;
  coordGeocodePoint_YHI?: CoordGeocodePoint | null;
  latitude: number;
  longitude: number;
}

interface CoordGeocodePoint {
  valueKind: number;
}

export interface IGetDraftPointsRequest {
  jobNum?: string;
  bhnum?: string;
}

export enum EUpdateBoreholeFormKey {
  bljobnum = 'bljobnum',
  blwrkord = 'blwrkord',
  blbhnum = 'blbhnum',
  blrmks = 'blrmks',
  blngT200 = 'blngT200',
  blG3ROCK = 'blG3ROCK',
  blG2ROCK = 'blG2ROCK',
  blgndlvl = 'blgndlvl',
  fileName = 'fileName',
}
