/* eslint-disable @typescript-eslint/no-explicit-any */
import { LabelWithMessage } from '@common/LabelWithMessage';
import { FETCH_QUERIES_NAME } from '@common/constants';
import { ApiErrorDisplay } from '@core/ApiErrorDisplay';
import { ApiSuccessToast } from '@core/ApiSuccessToast';
import {
  ComponentType,
  FieldValues,
  Form,
  FormFieldMethods,
  FormTextField,
  Grid,
  LoadingButton,
  requiredRule,
  useForm,
  useModal,
} from '@gcl/react-core';
import { useQueryClient } from '@tanstack/react-query';
import { EUpdateBoreholeFormKey, IBoreholeLogDraft } from './agsAdmin.models';
import { useUpdateBoreholeLog, useUpdateBoreholeLogAndApprov } from './agsAdmin.services';

interface IAgsUpdateModalProps {
  item?: IBoreholeLogDraft;
}
export const useAgsUpdateModal = ({ item }: IAgsUpdateModalProps) => {
  const queryClient = useQueryClient();

  const useFormMethods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { openPortal, closePortal, renderModal } = useModal();

  const {
    isPending: isLoadingUpdateBoreholeLog,
    error: errorUpdateBoreholeLog,
    isError: isErrorUpdateBoreholeLog,
    isSuccess: isSuccessUpdateBoreholeLog,
    reset: resetUpdateBoreholeLog,
    mutate: mutateUpdateBoreholeLog,
  } = useUpdateBoreholeLog();

  const {
    isPending: isLoadingUpdateBoreholeLogAndApprov,
    error: errorUpdateBoreholeLogAndApprov,
    isError: isErrorUpdateBoreholeLogAndApprov,
    isSuccess: isSuccessUpdateBoreholeLogAndApprov,
    reset: resetUpdateBoreholeLogAndApprov,
    mutate: mutateUpdateBoreholeLogAndApprov,
  } = useUpdateBoreholeLogAndApprov();

  const handleSubmit = (data: any, isUpdateAndApprov: boolean) => {
    if (isUpdateAndApprov) {
      mutateUpdateBoreholeLogAndApprov(data);
    } else {
      mutateUpdateBoreholeLog(data);
    }
  };

  const openAgsUpdateModal = (e: any) => {
    useFormMethods.reset();
    openPortal(e);
  };

  const getDefaultFormValues = (localItem: IBoreholeLogDraft | undefined) => {
    return localItem ?? {};
  };

  const renderAgsUpdateModal = () => {
    return renderModal({
      title: 'Update',
      onRenderBody: () => {
        return (
          <>
            <ApiErrorDisplay
              errors={[
                { error: errorUpdateBoreholeLog, genericError: 'Failed to update', reset: resetUpdateBoreholeLog },
                {
                  error: errorUpdateBoreholeLogAndApprov,
                  genericError: 'Failed to update and approve',
                  reset: resetUpdateBoreholeLogAndApprov,
                },
              ]}
            />
            <ApiSuccessToast
              successes={[
                {
                  isSuccess: isSuccessUpdateBoreholeLog,
                  reset: resetUpdateBoreholeLog,
                  message: 'Update Success',
                },
                {
                  isSuccess: isSuccessUpdateBoreholeLogAndApprov,
                  reset: resetUpdateBoreholeLogAndApprov,
                  message: 'Update and Approve Success',
                },
              ]}
              callbackAfterToasted={() => {
                if (isSuccessUpdateBoreholeLog || isSuccessUpdateBoreholeLogAndApprov) {
                  queryClient.invalidateQueries({ queryKey: [FETCH_QUERIES_NAME.GET_DRAFT_POINTS] });
                  if (closePortal && typeof closePortal === 'function') closePortal();
                }
              }}
            />
            <Form
              defaultValues={{
                callback: () => getDefaultFormValues(item),
                deps: [item],
              }}
              useFormMethods={useFormMethods}
              render={(formFieldMethods: FormFieldMethods<FieldValues>) => {
                return (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.bljobnum} text="Job code" />
                        <FormTextField
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.bljobnum}
                          rules={requiredRule}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blwrkord} text="Work order" />
                        <FormTextField
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blwrkord}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blbhnum} text="Borehole number" />
                        <FormTextField
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blbhnum}
                          rules={requiredRule}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blrmks} text="Remarks" />
                        <FormTextField
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blrmks}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blngT200} text="H Ple" />
                        <FormTextField
                          rules={{
                            validate: (value: number) => {
                              if (!value || value > 0) return true;
                              return 'Can only input numeric value';
                            },
                          }}
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blngT200}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blG3ROCK} text="Grade Ⅲ" />
                        <FormTextField
                          rules={{
                            validate: (value: number) => {
                              if (!value || value > 0) return true;
                              return 'Can only input numeric value';
                            },
                          }}
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blG3ROCK}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blG2ROCK} text="Grade Ⅱ" />
                        <FormTextField
                          rules={{
                            validate: (value: number) => {
                              if (!value || value > 0) return true;
                              return 'Can only input numeric value';
                            },
                          }}
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blG2ROCK}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.blgndlvl} text="Ground Level" />
                        <FormTextField
                          rules={{
                            validate: (value: number) => {
                              if (value && value > 0) return true;
                              return 'Can only input numeric value';
                            },
                          }}
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.blgndlvl}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelWithMessage labelFor={EUpdateBoreholeFormKey.fileName} text="Attachment" />
                        <FormTextField
                          className="gcl-mb-xs"
                          formFieldMethods={formFieldMethods}
                          id={EUpdateBoreholeFormKey.fileName}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            />
          </>
        );
      },
      onRenderFooter: () => {
        return (
          <Grid container flexWrap="nowrap" justifyContent="flex-end">
            <LoadingButton
              className="gcl-mr-sm"
              isLoading={isLoadingUpdateBoreholeLog}
              isSuccess={isSuccessUpdateBoreholeLog}
              isError={isErrorUpdateBoreholeLog}
              type={ComponentType.Primary}
              onClick={async () => {
                await useFormMethods.handleSubmit((data) => handleSubmit(data, false))();
              }}
            >
              Save
            </LoadingButton>
            <LoadingButton
              isLoading={isLoadingUpdateBoreholeLogAndApprov}
              isSuccess={isSuccessUpdateBoreholeLogAndApprov}
              isError={isErrorUpdateBoreholeLogAndApprov}
              type={ComponentType.Primary}
              onClick={async () => {
                await useFormMethods.handleSubmit((data) => handleSubmit(data, true))();
              }}
            >
              Save and Approve
            </LoadingButton>
          </Grid>
        );
      },
    });
  };

  return {
    openPortal: openAgsUpdateModal,
    closePortal,
    renderModal: renderAgsUpdateModal,
  };
};
