import { Button, Checkbox, ComponentType, Grid, Icon } from '@gcl/react-core';
import * as React from 'react';
import './AgsTable.scss';
import { IBoreholeLogDraft } from './agsAdmin.models';
import { useAgsUpdateModal } from './agsUpdateModal.hooks';

export interface IAgsTableProps {
  items?: IBoreholeLogDraft[];
  isEditable?: boolean;
  notifyOnChangeSelectedRows?: (selectedRows: string[]) => void;
}

export const AgsTable: React.FC<IAgsTableProps> = ({ items = [], isEditable = false, notifyOnChangeSelectedRows }) => {
  const columns: { [k in keyof IBoreholeLogDraft]?: { name: string; basis: string } } = {
    bljobnum: {
      name: 'Job Number',
      basis: '100px',
    },
    blwrkord: {
      name: 'Work Order',
      basis: '100px',
    },
    blbhnum: {
      name: 'Borehole Log',
      basis: '100px',
    },
    blrmks: {
      name: 'Remarks',
      basis: '100px',
    },
    blngT200: {
      name: 'H Ple',
      basis: '100px',
    },
    blG3ROCK: {
      name: 'Grade Ⅲ',
      basis: '100px',
    },
    blG2ROCK: {
      name: 'Grade Ⅱ',
      basis: '100px',
    },
    blgndlvl: {
      name: 'Ground Level',
      basis: '100px',
    },
    fileName: {
      name: 'Attachment',
      basis: '100px',
    },
  };

  const [updateAgsItem, setUpdateAgsItem] = React.useState<IBoreholeLogDraft | undefined>(undefined);
  const { openPortal, renderModal } = useAgsUpdateModal({ item: updateAgsItem });
  const [checkAll, setCheckAll] = React.useState<boolean>(false);
  const [checkedRows, setCheckedRows] = React.useState<{ [key: string]: boolean }>({});
  const onClickCheckAll = (checked: boolean) => {
    setCheckAll(checked);
    setCheckedRows((old) => {
      const newCheckedRows: { [key: string]: boolean } = {};
      items.forEach((item) => {
        newCheckedRows[item.id] = checked;
      });
      return newCheckedRows;
    });
  };
  const onClickCheckRow = (id: string, checked: boolean) => {
    setCheckedRows((old) => {
      return {
        ...old,
        [id]: checked,
      };
    });
  };

  React.useEffect(() => {
    if (typeof notifyOnChangeSelectedRows === 'function') {
      const newCheckedRows = Object.entries(checkedRows)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
      notifyOnChangeSelectedRows(newCheckedRows);
    }
  }, [JSON.stringify(checkedRows)]);

  const onClickUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    setUpdateAgsItem(items.find((item) => item.id === id));
    openPortal(e);
  };

  return (
    <Grid container className="gcl-borehole-log-grid-container">
      {renderModal()}
      <Grid item container className="gcl-borehole-log-grid-row gcl-borehole-log-grid-header">
        {isEditable && (
          <Grid item flexShrink={1} flexBasis="50px" className="gcl-borehole-log-grid-row-item">
            <Checkbox id="checkAll" checked={checkAll} onChange={onClickCheckAll} />
          </Grid>
        )}
        {Object.keys(columns).map((key) => {
          return (
            <Grid
              item
              key={key}
              flexGrow={1}
              flexShrink={1}
              flexBasis={columns[key as keyof typeof columns]?.basis}
              className="gcl-borehole-log-grid-row-item"
            >
              {columns[key as keyof typeof columns]?.name}
            </Grid>
          );
        })}
        {isEditable && (
          <Grid item flexShrink={1} flexBasis="100px" className="gcl-borehole-log-grid-row-item">
            <span> </span>
          </Grid>
        )}
      </Grid>
      {items.map((item) => {
        return (
          <Grid key={item.id} item container className="gcl-borehole-log-grid-row">
            {isEditable && (
              <Grid item flexShrink={1} flexBasis="50px" className="gcl-borehole-log-grid-row-item">
                <Checkbox
                  id={item.id}
                  checked={checkedRows[item.id]}
                  onChange={(checked) => onClickCheckRow(item.id, checked)}
                />
              </Grid>
            )}
            {Object.keys(columns).map((key) => {
              return (
                <Grid
                  item
                  key={key}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis={columns[key as keyof typeof columns]?.basis}
                  className="gcl-borehole-log-grid-row-item"
                >
                  <>
                    {key === 'fileName' ? (
                      item[key as keyof typeof columns] ? (
                        <Icon
                          className="gcl-ic-btn-md"
                          onClick={() => window.open(item[key as keyof typeof columns] as string, '_blank')}
                          iconName="ArrowDownCircle"
                        />
                      ) : null
                    ) : (
                      item[key as keyof typeof columns]
                    )}
                  </>
                </Grid>
              );
            })}
            {isEditable && (
              <Grid item flexShrink={1} flexBasis="100px" className="gcl-borehole-log-grid-row-item">
                <Button small type={ComponentType.Primary} onClick={(e) => onClickUpdate(e, item.id)}>
                  Update
                </Button>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
